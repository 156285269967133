import React from 'react'
import styled from '@emotion/styled'

import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import { LoggedInNavbar } from 'components/fragments/LoggedInNavbar/LoggedInNavbar'
import { SEO } from 'components/fragments/SEO/SEO'
import { PageTitle } from 'components/styles/Typography'
import { Button } from 'components/styles/Button'

const TextWrapper = styled.div`
  padding-bottom: 32px;
`

export const InvestStartView: React.FC = () => {
  return (
    <>
      <SEO title="Gårdskapital" />
      <LoggedInNavbar />
      <NavOffset fullNav />
      <ContentWrapper narrow>
        <TextWrapper>
          <PageTitle>
            Välkommen att investera i Gårdskapital. Nu sätter vi fart på svenskt
            lantbruk!
          </PageTitle>
          <p>
            På länken nedan kommer du att få svara på några frågor vi är
            skydliga att ställa enligt vår policy om Kundkännedom. Därefter
            anmäler du önskat belopp som du vill teckna dig för i Gårdskapitals
            räntefond.
            <br />
            <br />
            Förfarandet beräknas ta ca 1 - 3 minuter. Sedan skickar Gårdskapital
            en teckningsblankett till dig med efterföljande
            betalningsinstruktion.
          </p>
        </TextWrapper>
        <a href={process.env.GATSBY_INVESTOR_FORM_URL} target="_blank">
          <Button
            variant="primary"
            size="lg"
            fullWidth
            style={{ maxWidth: 360 }}
          >
            Kom igång!
          </Button>
        </a>
      </ContentWrapper>
    </>
  )
}
