import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useAuth } from 'utils/useAuth'
import { useStore } from 'components/store'

interface ProtectedRouteProps extends RouteComponentProps {
  component: React.FC<any>
  [prop: string]: any
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const {
    state: { userData },
  } = useStore()
  useAuth()

  return <>{userData ? <Component {...rest} /> : null}</>
}
