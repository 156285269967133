import { useEffect } from 'react'
import { useStore } from 'components/store'
import { auth } from './firebase'
import { navigate } from 'gatsby'

export const useAuth = (): void => {
  const { dispatch } = useStore()

  useEffect(() => {
    auth?.onAuthStateChanged(async user => {
      if (user) {
        dispatch({
          type: 'setUserData',
          data: { personalNumber: user.uid },
        })
      } else {
        navigate('/')
      }
    })
  }, [])
}
