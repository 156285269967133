import React from 'react'
import styled from '@emotion/styled'

import { SEO } from 'components/fragments/SEO/SEO'
import { Navbar } from 'components/fragments/Navbar/Navbar'
import { Button, ButtonGroup } from 'components/styles/Button'
import { Floater } from 'components/styles/Floater'
import { Link } from 'components/styles/Link'
import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import { PageSubtitle, PageTitle } from 'components/styles/Typography'
import { Desktop, Mobile } from 'components/styles/Breaks'

const CenterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const MobileConfirmationView: React.FC = () => {
  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <ContentWrapper>
        <PageTitle center>Skickat! Vad händer nu?</PageTitle>
        <PageSubtitle center>
          Visste du att vi erbjuder kostnadsfri personlig rådgivning gällande
          din investering?
          <br />
          <br />
          Boka in ett möte med en av våra rådgivare så går vi igenom mallarna
          tillsammans!
        </PageSubtitle>
      </ContentWrapper>
      <Floater>
        <ButtonGroup>
          <Link to="/mote#email">
            <Button variant="primary" size="lg" fullWidth>
              Boka ett uppföljningsmöte
            </Button>
          </Link>
          <Link to="/" style={{ alignSelf: 'center' }}>
            <Button variant="text" size="lg" fullWidth>
              Tillbaka till portalen
            </Button>
          </Link>
        </ButtonGroup>
      </Floater>
    </>
  )
}

const DesktopConfirmationView: React.FC = () => {
  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <CenterContentWrapper>
        <PageTitle center>Skickat! Vad händer nu?</PageTitle>
        <PageSubtitle center style={{ marginBottom: 48 }}>
          Visste du att vi erbjuder kostnadsfri personlig rådgivning gällande
          din investering?
          <br />
          <br />
          Boka in ett möte med en av våra rådgivare så går vi igenom mallarna
          tillsammans!
        </PageSubtitle>
        <ButtonGroup>
          <Link to="/mote#email">
            <Button variant="primary" size="md">
              Boka ett uppföljningsmöte
            </Button>
          </Link>
          <Link to="/" style={{ alignSelf: 'center' }}>
            <Button variant="text" size="md">
              Tillbaka till portalen
            </Button>
          </Link>
        </ButtonGroup>
      </CenterContentWrapper>
    </>
  )
}

export const ConfirmationView: React.FC = () => {
  return (
    <>
      <Mobile>
        <MobileConfirmationView />
      </Mobile>
      <Desktop>
        <DesktopConfirmationView />
      </Desktop>
    </>
  )
}
