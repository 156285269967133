import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'assets/svg/Logos'
import { Link } from 'components/styles/Link'

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
`

export const Navbar: React.FC = () => {
  return (
    <>
      <Wrapper>
        <Link to="/">
          <Icon />
        </Link>
      </Wrapper>
    </>
  )
}
