import styled from '@emotion/styled'
import css from '@emotion/css'

export const PageTitle = styled.h1<{ center?: boolean }>`
  ${({ center }) => css`
    ${center ? 'text-align: center;' : ''}
    margin-bottom: 24px;
  `}
`

export const PageSubtitle = styled.p<{ center?: boolean }>`
  ${({ center }) => css`
    ${center ? 'text-align: center;' : ''}
    margin-bottom: 24px;
  `}
`

export const PaddedParagraph = styled.p`
  margin: 16px 0;
`
