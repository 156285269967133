import React, { useCallback } from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'

import { SEO } from 'components/fragments/SEO/SEO'
import { Navbar } from 'components/fragments/Navbar/Navbar'
import { Button, ButtonGroup } from 'components/styles/Button'
import { Floater } from 'components/styles/Floater'
import { PageSubtitle, PageTitle } from 'components/styles/Typography'
import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import { api } from 'utils/api'
import { Desktop, Mobile } from 'components/styles/Breaks'

interface NewsletterSignupViewProps {
  nextPath: string
}

const MobileNewsletterSignupView: React.FC<NewsletterSignupViewProps> = ({
  nextPath,
}) => {
  const signUpToNewsletter = useCallback(() => {
    api.post('/signUpToNewsletter', { email: localStorage.getItem('email') })
    localStorage.setItem('hasSeenNewsletterSignup', JSON.stringify(true))
    navigate(nextPath)
  }, [])

  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <ContentWrapper>
        <PageTitle center>Gårdskapitals nyhetsbrev</PageTitle>
        <PageSubtitle center>
          I Gårdskapitals nyhetsbrev får du via e-post tillgång till
        </PageSubtitle>
        <ul>
          <li>
            Tips och tricks för att minska dina risker och öka din avkastning
          </li>
          <li>Nyheter och inspiration från branschen</li>
          <li>Spaningar och analyser från våra analytiker och rådgivare</li>
          <li>Uppdateringar om Gårdskapital</li>
        </ul>
      </ContentWrapper>
      <Floater>
        <ButtonGroup>
          <Button variant="primary" size="lg" onClick={signUpToNewsletter}>
            Ja tack, jag vill ha nyhetsbrevet
          </Button>
          <Button variant="text" size="lg" onClick={signUpToNewsletter}>
            Nej tack
          </Button>
        </ButtonGroup>
      </Floater>
    </>
  )
}

const CenterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  margin-top: -80px;
`

const DesktopNewsletterSignupView: React.FC<NewsletterSignupViewProps> = ({
  nextPath,
}) => {
  const signUpToNewsletter = useCallback(() => {
    api.post('/signUpToNewsletter', { email: localStorage.getItem('email') })
    localStorage.setItem('hasSeenNewsletterSignup', JSON.stringify(true))
    navigate(nextPath)
  }, [])

  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <CenterContentWrapper>
        <PageTitle center>Gårdskapitals nyhetsbrev</PageTitle>
        <PageSubtitle center>
          I Gårdskapitals nyhetsbrev får du via e-post tillgång till
        </PageSubtitle>
        <ul>
          <li>
            Tips och tricks för att minska dina risker och öka din avkastning
          </li>
          <li>Nyheter och inspiration från branschen</li>
          <li>Spaningar och analyser från våra analytiker och rådgivare</li>
          <li>Uppdateringar om Gårdskapital</li>
        </ul>
        <ButtonGroup style={{ marginTop: 18 }}>
          <Button variant="primary" size="md" onClick={signUpToNewsletter}>
            Ja tack, jag vill ha nyhetsbrevet
          </Button>
          <Button variant="text" size="md" onClick={signUpToNewsletter}>
            Nej tack
          </Button>
        </ButtonGroup>
      </CenterContentWrapper>
    </>
  )
}

export const NewsletterSignupView: React.FC<NewsletterSignupViewProps> = props => {
  return (
    <>
      <Mobile>
        <MobileNewsletterSignupView {...props} />
      </Mobile>
      <Desktop>
        <DesktopNewsletterSignupView {...props} />
      </Desktop>
    </>
  )
}
