import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

let firebaseConfig = {
  apiKey: 'AIzaSyCcjYmKlbM7bUn1DPRthpV9XoTVLt0mQxM',
  authDomain: 'gardskapital.firebaseapp.com',
  projectId: 'gardskapital',
  storageBucket: 'gardskapital.appspot.com',
  messagingSenderId: '682253033108',
  appId: '1:682253033108:web:fd58e1c0a1c285aaea1e81',
}

const firebaseApp =
  typeof window !== `undefined` ? firebase.initializeApp(firebaseConfig) : null

const auth = firebaseApp ? firebaseApp.auth() : null
const db = firebaseApp ? firebaseApp.firestore() : null

export { auth, db }
