import _ from 'lodash'

import { logout } from './logout'

const INACTIVE_USER_TIME_THRESHOLD = 1800000

let userActivityTimeout: NodeJS.Timeout | null = null

const resetUserActivityTimeout = () => {
  if (userActivityTimeout) {
    clearTimeout(userActivityTimeout)
  }
  userActivityTimeout = setTimeout(() => {
    logout()
  }, INACTIVE_USER_TIME_THRESHOLD)
}

const resetUserActivityTimeoutThrottled = _.throttle(
  resetUserActivityTimeout,
  1000
)

export const activateActivityTracker = () => {
  window.addEventListener('mousemove', resetUserActivityTimeoutThrottled)
  window.addEventListener('scroll', resetUserActivityTimeoutThrottled)
  window.addEventListener('keydown', resetUserActivityTimeoutThrottled)
  window.addEventListener('resize', resetUserActivityTimeoutThrottled)
}
