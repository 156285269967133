import { navigate } from 'gatsby'
import { auth } from 'utils/firebase'
import { useStore } from 'components/store'

export const useLogout = () => {
  const { dispatch } = useStore()

  const logout = async () => {
    await auth?.signOut()
    dispatch({
      type: 'setUserData',
      data: { personalNumber: '' },
    })
    navigate('/')
  }

  return logout
}
