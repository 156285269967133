import React, { useCallback } from 'react'
import { useLocation } from '@reach/router'
import { z } from 'zod'
import { navigate } from 'gatsby'
import type { RouteComponentProps } from '@reach/router'

import { QuestionView } from 'components/views/QuestionView/QuestionView'
import { OverviewView } from 'components/views/TemplatesView/components/OverviewView'
import { NewsletterSignupView } from '../NewsletterSignupView/NewsletterSignupView'
import { api } from 'utils/api'

import { ConfirmationView } from './components/ConfirmationView'
import { SelectView } from './components/SelectView'

export const TemplatesView: React.FC<RouteComponentProps> = () => {
  const location = useLocation()

  const sendTemplates = useCallback(
    values => {
      const search = new URLSearchParams(location.search)
      const files = JSON.parse(search.get('files')!)

      api.post('/sendTemplates', { email: values.email, files })
      localStorage.setItem('email', values.email)

      if (!localStorage.getItem('hasSeenNewsletterSignup')) {
        navigate('/mallar#newsletter')
      } else {
        navigate('/mallar#bekraftelse')
      }
    },
    [location]
  )

  switch (location.hash) {
    case '':
      return <OverviewView />
    case '#select':
      return <SelectView />
    case '#email':
      return (
        <QuestionView
          title="Ange e-posten som vi ska skicka mallarna till"
          validator={z.string().email()}
          errorMessage="Ogiltig e-post"
          type="email"
          name="email"
          placeholder="namn@adress.se"
          initialValue={localStorage.getItem('email') || ''}
          buttonText="Nästa"
          onSubmit={sendTemplates}
        />
      )
    case '#newsletter':
      return <NewsletterSignupView nextPath="/mallar#bekraftelse" />
    case '#bekraftelse':
      return <ConfirmationView />
    default:
      return null
  }
}
