import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { ContentWrapper } from 'components/styles/Layout'
import { LoggedInNavbar } from 'components/fragments/LoggedInNavbar/LoggedInNavbar'
import axios from 'axios'
import { auth, db } from 'utils/firebase'
import { useStore } from 'components/store'
import ClipLoader from 'react-spinners/ClipLoader'
import { SEO } from 'components/fragments/SEO/SEO'
import { Button } from 'components/styles/Button'
import { PageSubtitle, PageTitle } from 'components/styles/Typography'
import { NavOffset } from 'components/styles/Layout'
import { Desktop, Mobile } from 'components/styles/Breaks'

export const AcceptTermsView: React.FC = () => {
  const [isSignaturesLoading, setIsSignaturesLoading] = useState(true)
  const [form, setForm] = useState({
    acceptTerms: false,
    acceptUC: false,
    confirmNoPep: false,
    confirmTaxResidence: false,
  })

  const { state } = useStore()
  const personalNumber = state?.userData?.personalNumber

  useEffect(() => {
    const getUserSignatures = async () => {
      const signaturesSnapshot = await db
        ?.collection('signatures')
        .where('user.personalNumber', '==', personalNumber)
        .get()
      if (!signaturesSnapshot || signaturesSnapshot.docs.length === 0) {
        setIsSignaturesLoading(false)
        return
      }
      if (signaturesSnapshot?.docs.length > 0) {
        navigate('/apply/formular', { replace: true })
      }
    }
    getUserSignatures()
  }, [personalNumber])

  const [isSigning, setIsSigning] = useState(false)

  const canProceed = Object.values(form).every(accepted => accepted === true)

  const handleChange = ({ target }) => {
    setForm(currentForm => ({
      ...currentForm,
      [target.name]: !currentForm[target.name],
    }))
  }

  const handleSignature = async () => {
    setIsSigning(true)
    const idToken = await auth?.currentUser?.getIdToken()
    const res = await axios.post(
      `${process.env.GATSBY_API_URL}/signPrivacyPolicy`,
      { idToken }
    )

    if (res.status !== 200) {
      alert('Något gick fel')
    }
    navigate('/apply/formular')
    setIsSigning(false)
  }

  return (
    <>
      <SEO title="Gårdskapital" />
      <LoggedInNavbar />
      <NavOffset fullNav />
      {isSignaturesLoading ? (
        <div style={{ textAlign: 'center' }}>
          <ClipLoader color="#ffffff" />
        </div>
      ) : (
        <>
          <ContentWrapper narrow>
            {isSigning ? (
              <PageSubtitle center>Starta BankID-appen</PageSubtitle>
            ) : (
              <>
                <PageTitle>Villkor</PageTitle>
                <PageSubtitle>
                  För att gå vidare behöver du godkänna Gårdskapitals{' '}
                  <Link
                    to="/personuppgiftspolicy"
                    style={{ color: '#ffffff', textDecoration: 'underline' }}
                  >
                    personuppgiftspolicy
                  </Link>{' '}
                  samt att vi begär en kreditupplysning. Du behöver även
                  bekräfta att du inte är en person i{' '}
                  <Link
                    to="/pep"
                    style={{ color: '#ffffff', textDecoration: 'underline' }}
                  >
                    politiskt utsatt ställning (PEP)
                  </Link>
                </PageSubtitle>

                <form>
                  <input
                    type="checkbox"
                    id="acceptTerms"
                    name="acceptTerms"
                    checked={form['acceptTerms']}
                    onChange={handleChange}
                  />
                  <label htmlFor="acceptTerms">
                    Jag godkänner Gårdskapitals behandling av personuppgifter
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="acceptUC"
                    name="acceptUC"
                    checked={form['acceptUC']}
                    onChange={handleChange}
                  />
                  <label htmlFor="acceptUC">
                    Jag accepterar att Gårdskapital begär en kreditupplysning på
                    mig
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="confirmNoPep"
                    name="confirmNoPep"
                    checked={form['confirmNoPep']}
                    onChange={handleChange}
                  />
                  <label htmlFor="confirmNoPep">
                    Jag bekräftar att varken jag, mina närstående eller ev.
                    medarbetare är att betrakta som personer i politiskt utsatt
                    ställning (PEP)
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="confirmTaxResidence"
                    name="confirmTaxResidence"
                    checked={form['confirmTaxResidence']}
                    onChange={handleChange}
                  />
                  <label htmlFor="confirmTaxResidence">
                    Jag bekräftar att mitt företag har sin skatterättsliga
                    hemvist i Sverige
                  </label>
                  <br />
                  <br />
                </form>
                <Mobile>
                  <Button
                    variant="primary"
                    size="lg"
                    disabled={!canProceed}
                    fullWidth
                    onClick={handleSignature}
                  >
                    Signera med mobilt BankID
                  </Button>
                </Mobile>
                <Desktop>
                  <Button
                    variant="primary"
                    size="md"
                    disabled={!canProceed}
                    style={{ marginTop: 16 }}
                    onClick={handleSignature}
                  >
                    Signera med mobilt BankID
                  </Button>
                </Desktop>
              </>
            )}
          </ContentWrapper>
        </>
      )}
    </>
  )
}
