import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Icon } from 'assets/svg/Logos'
import { useStore } from 'components/store'
import { Role } from 'types/custom'
import { useLogout } from 'utils/useLogout'

const NavWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Nav = styled.div`
  width: 100%;
  height: 80px;
  background: ${props => props.theme.color.darkGreen};
  border-bottom: 1px solid #003d36;
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const NavbarContentWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${props => props.theme.space.main.mobile}px;
`

export const LoggedInNavbar: React.FC = () => {
  const {
    state: { userData, userRole },
  } = useStore()
  const logout = useLogout()

  if (!userData) {
    return null
  }

  const { personalNumber } = userData

  return (
    <>
      <NavWrapper>
        <Nav>
          <NavbarContentWrapper>
            <Link to={userRole === Role.LOAN_APPLICANT ? '/apply' : '/invest'}>
              <Icon />
            </Link>
            <p style={{ textAlign: 'right' }}>
              {personalNumber.slice(0, 8) + '–' + personalNumber.slice(8, 12)}
              <br />
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={logout}
              >
                Logga ut
              </span>
            </p>
          </NavbarContentWrapper>
        </Nav>
      </NavWrapper>
    </>
  )
}
