import React from 'react'
import styled from '@emotion/styled'
import css from '@emotion/css'

const DEFAULT_GAP = 16

const FlexGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

interface Breakpoint {
  breakpoint: number
  nColumns: number
}

const getWidthString = (
  currentBreakpoint: Breakpoint,
  nextBreakpoint: Breakpoint
) => {
  return `(min-width: ${currentBreakpoint.breakpoint}px) ${
    nextBreakpoint ? `and (max-width: ${nextBreakpoint.breakpoint}px)` : ''
  }
  `
}

const getCardWidth = (breakpoint: Breakpoint, gap?: number) => {
  const nGaps = breakpoint.nColumns - 1
  const gapSize = gap || DEFAULT_GAP

  return `calc(${100 / breakpoint.nColumns}% - ${
    // Let the total gap size evenly affect the width all columns
    (gapSize * nGaps) / breakpoint.nColumns
  }px)`
}

const Card = styled.div<{
  breakpoints?: Breakpoint[]
  gap?: number // px
}>`
  ${({ breakpoints, gap }) => css`
    display: flex;
    margin-bottom: ${gap || DEFAULT_GAP}px;
    margin-right: ${gap || DEFAULT_GAP}px;
    box-sizing: border-box;

    ${breakpoints?.map(
      (breakpoint, i) => css`
        @media ${getWidthString(breakpoint, breakpoints[i + 1] || null)} {
          width: ${getCardWidth(breakpoint, gap)};
          &:nth-of-type(${breakpoint.nColumns}n) {
            margin-right: 0;
          }
          ${breakpoint.nColumns === 1 ? 'margin-right: 0;' : ''}
        }
      `
    )}
  `}
`

interface FlexGridProps {
  breakpoints: Breakpoint[]
  gap?: number
}

const FlexGrid: React.FC<FlexGridProps> & { Card: typeof Card } = ({
  breakpoints,
  gap,
  children,
}) => (
  <FlexGridContainer>
    {React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child
      }
      return React.cloneElement(child, { breakpoints, gap })
    })}
  </FlexGridContainer>
)

FlexGrid.Card = Card

export default FlexGrid
