import React from 'react'
import styled from '@emotion/styled'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { LoggedInNavbar } from 'components/fragments/LoggedInNavbar/LoggedInNavbar'
import { SEO } from 'components/fragments/SEO/SEO'
import { NavOffset } from 'components/styles/Layout'
import { ContentWrapper } from 'components/styles/Layout'

const LongContent = styled.div`
  @media screen and (min-width: 800px) {
    margin-top: 160px;
  }
  h1 {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 16px;
  }
`

const options = {
  renderText: (text: string) =>
    text
      .split('\n')
      .flatMap((text: string, i: number) => [i > 0 && <br />, text]),
}

interface TermsViewProps {
  rawContent: string
}

export const TermsView: React.FC<TermsViewProps> = ({ rawContent }) => {
  const json = JSON.parse(rawContent)

  return (
    <>
      <SEO title="Gårdskapital" />
      <LoggedInNavbar />
      <NavOffset fullNav />
      <ContentWrapper style={{ marginBottom: 120 }}>
        <LongContent>{documentToReactComponents(json, options)}</LongContent>
      </ContentWrapper>
    </>
  )
}
