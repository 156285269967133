import React from 'react'

export const FullLogo: React.FC = () => {
  return (
    <svg
      width="127"
      height="43"
      viewBox="0 0 127 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M54.5891 4.08014C54.311 3.82908 53.906 3.82908 53.6278 4.08014L47.1554 9.93234C46.9866 10.0849 46.8889 10.3104 46.8889 10.5478V21.2949C46.8889 21.7388 47.2233 22.0985 47.6355 22.0985H52.6616C53.0738 22.0985 53.4082 21.7388 53.4082 21.2949V16.1597H54.902V21.1417C54.902 21.1684 54.907 21.1928 54.9092 21.2183C54.907 21.2438 54.902 21.2688 54.902 21.2949C54.902 21.7388 55.2364 22.0985 55.6486 22.0985H60.5814C60.9936 22.0985 61.3286 21.7388 61.3286 21.2949V10.5478C61.3286 10.3104 61.2309 10.0849 61.0621 9.93234L54.5891 4.08014ZM59.8342 20.4912H56.3958V15.356C56.3958 14.9121 56.0614 14.5524 55.6486 14.5524H52.6616C52.2488 14.5524 51.9144 14.9121 51.9144 15.356V20.4912H48.3827V10.9223L54.1085 5.74499L59.8342 10.9223V20.4912Z"
          fill="#7DBBA9"
        />
        <path
          d="M79.4308 9.90078H74.9467V6.57108C75.6552 6.4773 76.67 6.1936 77.5662 5.37453C78.6676 4.36968 79.2929 2.85084 79.4259 0.860736C79.4402 0.646472 79.374 0.435176 79.2421 0.273736C79.1102 0.112296 78.9237 0.0143636 78.7245 0.00130592C78.6897 0.00189946 75.712 -0.141141 74.1597 3.03009C72.6086 -0.141141 69.6309 0.000712394 69.5956 0.000712394C69.3958 0.01377 69.2098 0.111702 69.078 0.273142C68.9461 0.434582 68.8798 0.646472 68.8942 0.860736C69.0272 2.85084 69.6524 4.36909 70.7539 5.37453C71.6842 6.22387 72.7421 6.49808 73.4523 6.58058V17.606C73.4523 18.0499 73.7873 18.4096 74.1995 18.4096C74.6122 18.4096 74.9467 18.0499 74.9467 17.606V11.5081H78.6842V18.6767C78.6842 20.2027 78.0617 21.6402 76.9763 22.6213L72.9584 26.2543L68.94 22.6213C67.8545 21.6402 67.2321 20.2027 67.2321 18.6767V11.5081H71.1192C71.5314 11.5081 71.8663 11.1484 71.8663 10.7044C71.8663 10.2605 71.5314 9.90078 71.1192 9.90078H66.4855C66.0727 9.90078 65.7383 10.2605 65.7383 10.7044V18.6767C65.7383 20.6787 66.555 22.5643 67.9793 23.8517L72.4778 27.9191C72.6163 28.045 72.7873 28.1079 72.9579 28.1079C73.1289 28.1079 73.3 28.045 73.4385 27.9191L77.937 23.8517C79.3607 22.5643 80.1774 20.6787 80.1774 18.6767V10.7044C80.178 10.2605 79.8436 9.90078 79.4308 9.90078ZM77.8145 1.72135C77.6164 2.7719 77.2141 3.58266 76.6132 4.13702C76.106 4.60591 75.5272 4.82373 75.0625 4.92463C75.6602 2.70008 76.9559 1.96351 77.8145 1.72135ZM71.7201 4.14948C71.112 3.59394 70.7047 2.77962 70.5055 1.72135C71.3642 1.96351 72.6604 2.70008 73.2581 4.92463C72.7973 4.82551 72.2245 4.61006 71.7201 4.14948Z"
          fill="#7DBBA9"
        />
        <path
          d="M7.10652 40.0841V39.0205C6.40677 39.7731 5.59118 40.1494 4.65975 40.1494C4.08338 40.1494 3.54185 40.0253 3.03517 39.7772C2.52849 39.53 2.08773 39.1891 1.7129 38.7546C1.33807 38.32 1.04188 37.8085 0.824325 37.22C0.60583 36.6304 0.496582 35.9948 0.496582 35.3131C0.496582 34.6658 0.60583 34.0565 0.824325 33.4852C1.04188 32.9139 1.3423 32.4135 1.72561 31.984C2.10892 31.5545 2.56004 31.2131 3.07896 30.9599C3.59789 30.7077 4.16202 30.5815 4.77135 30.5815C5.59542 30.5815 6.30176 30.7674 6.89037 31.1392C7.47899 31.5119 7.92163 32.0129 8.2183 32.6419L6.94547 33.6508C6.72321 33.1818 6.41242 32.8232 6.0131 32.575C5.61378 32.3269 5.17915 32.2028 4.7092 32.2028C4.33908 32.2028 3.99956 32.2869 3.69065 32.455C3.38175 32.6232 3.11804 32.8536 2.89955 33.1463C2.68105 33.4391 2.512 33.7759 2.3924 34.1568C2.27373 34.5376 2.2144 34.9408 2.2144 35.3662C2.2144 35.8089 2.28033 36.2227 2.41218 36.6076C2.54309 36.9936 2.72391 37.3283 2.95465 37.612C3.18538 37.8956 3.45944 38.1195 3.77683 38.2836C4.09421 38.4466 4.43796 38.5282 4.80808 38.5282C5.65663 38.5282 6.42278 38.0987 7.10652 37.2397V36.5757H5.33925V35.2325H8.51496V40.0841H7.10652Z"
          fill="#536B62"
        />
        <path
          d="M12.2163 40.0842L15.5644 30.6485H17.0717L20.4084 40.0842H18.6539L17.8388 37.7321H14.7747L13.9709 40.0842H12.2163ZM15.0586 36.4421H17.5294L16.3187 32.7347L15.0586 36.4421ZM15.1081 28.9468C15.1081 28.6196 15.2173 28.3674 15.4358 28.1901C15.6534 28.0128 15.9434 27.9242 16.306 27.9242C16.6686 27.9242 16.9591 28.0128 17.1776 28.1901C17.3961 28.3674 17.5054 28.6196 17.5054 28.9468C17.5054 29.275 17.3961 29.5277 17.1776 29.705C16.9591 29.8822 16.6686 29.9709 16.306 29.9709C15.9434 29.9709 15.6534 29.8822 15.4358 29.705C15.2173 29.5277 15.1081 29.275 15.1081 28.9468ZM16.306 28.4682C16.1666 28.4682 16.0494 28.5102 15.9543 28.5943C15.8591 28.6794 15.8116 28.7969 15.8116 28.9468C15.8116 29.0795 15.8591 29.1924 15.9543 29.2856C16.0494 29.3788 16.1666 29.4254 16.306 29.4254C16.4379 29.4254 16.5551 29.3813 16.6578 29.2932C16.7614 29.2041 16.8132 29.0886 16.8132 28.9468C16.8132 28.7969 16.7637 28.6794 16.6648 28.5943C16.566 28.5102 16.4463 28.4682 16.306 28.4682Z"
          fill="#536B62"
        />
        <path
          d="M26.2625 40.0842H24.5574V30.6486H28.4366C28.8406 30.6486 29.2136 30.7392 29.5554 30.9205C29.8973 31.1019 30.1916 31.3409 30.4384 31.6377C30.686 31.9345 30.8796 32.2713 31.019 32.6481C31.1593 33.025 31.2295 33.4038 31.2295 33.7847C31.2295 34.3955 31.0872 34.9516 30.8028 35.453C30.5194 35.9534 30.1346 36.314 29.6487 36.5348L31.6504 40.0842H29.7348L27.9436 36.9208H26.2625V40.0842ZM26.2625 32.2561V35.3132H28.3999C28.5562 35.3132 28.7026 35.2732 28.8392 35.1932C28.9748 35.1132 29.0921 35.0048 29.191 34.868C29.2898 34.7303 29.368 34.5682 29.4255 34.3818C29.4829 34.1964 29.5116 33.9974 29.5116 33.7847C29.5116 33.5628 29.4787 33.3592 29.4128 33.1739C29.3468 32.9875 29.2583 32.8254 29.1472 32.6876C29.036 32.5509 28.9084 32.4445 28.7643 32.3686C28.6202 32.2936 28.4743 32.2561 28.3264 32.2561H26.2625Z"
          fill="#536B62"
        />
        <path
          d="M39.0925 40.0842H35.9055V30.6486H39.0925C39.8092 30.6486 40.4374 30.7726 40.977 31.0208C41.5167 31.269 41.9678 31.6053 42.3304 32.0297C42.693 32.4552 42.9647 32.9535 43.1455 33.5248C43.3263 34.0962 43.4167 34.7054 43.4167 35.3527C43.4167 36.0699 43.316 36.7187 43.1144 37.2991C42.9129 37.8795 42.6224 38.3759 42.2428 38.7882C41.8642 39.2004 41.4093 39.5195 40.8782 39.7454C40.347 39.9713 39.7518 40.0842 39.0925 40.0842ZM41.7003 35.3527C41.7003 34.9009 41.6424 34.4846 41.5266 34.1037C41.4117 33.7229 41.2431 33.3952 41.0208 33.1207C40.7976 32.8452 40.5236 32.6324 40.1987 32.4825C39.8728 32.3316 39.5041 32.2561 39.0925 32.2561H37.6106V38.4752H39.0925C39.5126 38.4752 39.8855 38.3956 40.2114 38.2366C40.5363 38.0766 40.808 37.8573 41.0265 37.5787C41.245 37.2991 41.4117 36.9689 41.5266 36.588C41.6424 36.2072 41.7003 35.7954 41.7003 35.3527Z"
          fill="#536B62"
        />
        <path
          d="M53.9383 31.6315L53.1854 33.1205C53.1279 33.0577 53.0229 32.9731 52.8703 32.8668C52.7178 32.7604 52.5322 32.6566 52.3137 32.5553C52.0952 32.453 51.8584 32.3664 51.6032 32.2955C51.3479 32.2246 51.0885 32.1891 50.8248 32.1891C50.3558 32.1891 50.0059 32.2823 49.7752 32.4687C49.5444 32.6551 49.429 32.9164 49.429 33.2527C49.429 33.4472 49.4724 33.6088 49.559 33.7374C49.6457 33.8661 49.7709 33.979 49.9348 34.0762C50.0996 34.1745 50.3077 34.2631 50.5592 34.3421C50.8106 34.4222 51.1012 34.5062 51.4308 34.5944C51.8593 34.719 52.2483 34.8522 52.5977 34.994C52.948 35.1358 53.2447 35.3131 53.4877 35.5258C53.7307 35.7385 53.9181 35.9933 54.0499 36.2901C54.1818 36.5869 54.2477 36.952 54.2477 37.3856C54.2477 37.891 54.1592 38.3231 53.9821 38.6816C53.8051 39.0402 53.5663 39.3304 53.2659 39.5523C52.9645 39.7731 52.6184 39.9347 52.2276 40.037C51.8358 40.1393 51.4261 40.1904 50.9985 40.1904C50.3393 40.1904 49.6885 40.0841 49.0462 39.8714C48.4039 39.6586 47.8271 39.3573 47.3157 38.9673L48.07 37.3856C48.1444 37.4656 48.2782 37.5699 48.4712 37.6986C48.6653 37.8262 48.8936 37.9543 49.1564 38.083C49.4201 38.2116 49.7125 38.3205 50.0337 38.4097C50.3558 38.4978 50.6854 38.5419 51.0226 38.5419C51.9615 38.5419 52.431 38.2187 52.431 37.5725C52.431 37.3678 52.3778 37.1946 52.2714 37.0528C52.164 36.912 52.0114 36.7884 51.8137 36.6821C51.6159 36.5757 51.3771 36.478 51.0974 36.3888C50.8168 36.3007 50.5036 36.2035 50.158 36.0971C49.738 35.9725 49.3735 35.8373 49.0646 35.6914C48.7557 35.5455 48.4981 35.3728 48.2918 35.1733C48.0865 34.9737 47.9321 34.7433 47.8285 34.4819C47.7258 34.2206 47.6745 33.9081 47.6745 33.5444C47.6745 33.0663 47.7569 32.6414 47.9217 32.2696C48.0865 31.8969 48.3149 31.5869 48.6069 31.3397C48.8998 31.0916 49.2397 30.9032 49.6268 30.7745C50.0139 30.6459 50.4297 30.5815 50.8742 30.5815C51.492 30.5815 52.0604 30.6859 52.5793 30.8945C53.0983 31.1022 53.5513 31.3478 53.9383 31.6315Z"
          fill="#536B62"
        />
        <path
          d="M60.4466 40.0842H58.7415V30.6622H60.4466V35.14L64.128 30.6486H66.0055L62.6447 34.8346L66.216 40.0842H64.3004L61.5696 36.0046L60.4466 37.2657V40.0842Z"
          fill="#536B62"
        />
        <path
          d="M69.3774 40.0842L72.7255 30.6486H74.2328L77.5682 40.0842H75.8136L74.9985 37.7322H71.9344L71.132 40.0842H69.3774ZM72.2183 36.4422H74.6891L73.4784 32.7347L72.2183 36.4422Z"
          fill="#536B62"
        />
        <path
          d="M83.4224 40.0842H81.7173V30.6486H85.4369C85.8409 30.6486 86.2138 30.7392 86.5557 30.9205C86.8976 31.1019 87.1919 31.3409 87.4386 31.6377C87.6854 31.9345 87.8789 32.2713 88.0193 32.6481C88.1596 33.025 88.2297 33.4038 88.2297 33.7847C88.2297 34.1828 88.1638 34.5702 88.032 34.947C87.9001 35.3239 87.7146 35.6586 87.4754 35.9514C87.2371 36.2431 86.9489 36.4776 86.6108 36.6549C86.2727 36.8321 85.9021 36.9208 85.499 36.9208H83.4224V40.0842ZM83.4224 32.2561V35.3132H85.4001C85.7128 35.3132 85.9742 35.176 86.1842 34.9015C86.3942 34.6259 86.4992 34.2537 86.4992 33.7847C86.4992 33.5456 86.4662 33.3309 86.4003 33.1404C86.3344 32.95 86.2482 32.7879 86.1418 32.6542C86.0344 32.5215 85.9087 32.4222 85.7646 32.3564C85.6205 32.2895 85.4741 32.2561 85.3253 32.2561H83.4224Z"
          fill="#536B62"
        />
        <path
          d="M94.3467 40.0842H92.6416V30.6486H94.3467V40.0842Z"
          fill="#536B62"
        />
        <path
          d="M105.888 30.6486V32.2561H103.082V40.0842H101.39V32.2561H98.5859V30.6486H105.888Z"
          fill="#536B62"
        />
        <path
          d="M108.408 40.0842L111.758 30.6486H113.265L116.601 40.0842H114.846L114.031 37.7322H110.967L110.163 40.0842H108.408ZM111.251 36.4422H113.722L112.511 32.7347L111.251 36.4422Z"
          fill="#536B62"
        />
        <path
          d="M126.929 40.0842H120.75V30.6486H122.455V38.4752H126.929V40.0842Z"
          fill="#536B62"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="127" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Icon: React.FC = () => {
  return (
    <svg
      width="54"
      height="40"
      viewBox="0 0 54 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.4719 5.80551C12.0222 5.44797 11.3653 5.44797 10.9156 5.80551L0.431578 14.1334C0.157781 14.351 0 14.6711 0 15.0087V30.3021C0 30.9339 0.541687 31.4457 1.20952 31.4457H9.34959C10.0178 31.4457 10.5595 30.9339 10.5595 30.3021V22.995H12.979V30.0844C12.979 30.1219 12.9866 30.1574 12.9904 30.1937C12.9866 30.2299 12.979 30.265 12.979 30.3025C12.979 30.9343 13.5203 31.4457 14.1885 31.4457H22.178C22.8462 31.4457 23.3879 30.9343 23.3879 30.3025V15.0087C23.3879 14.6711 23.2297 14.351 22.9563 14.133L12.4719 5.80551ZM20.968 29.1585H15.3984V21.8514C15.3984 21.2201 14.8563 20.7083 14.1885 20.7083H9.34959C8.68134 20.7083 8.14008 21.2201 8.14008 21.8514V29.1585H2.41945V15.542L11.6935 8.17477L20.968 15.542V29.1585Z"
          fill="#7DBBA9"
        />
        <path
          d="M52.6938 14.0883H45.43V9.35021C46.5779 9.21708 48.2215 8.8129 49.6736 7.6482C51.4573 6.21724 52.4707 4.05645 52.6854 1.22481C52.7086 0.919888 52.6015 0.618947 52.388 0.389355C52.1749 0.159365 51.8725 0.0198557 51.5497 0.00152032C51.4932 0.00271612 46.6703 -0.201365 44.1564 4.31115C41.6432 -0.200967 36.8208 0.00112173 36.763 0.00112173C36.4398 0.0194572 36.1382 0.158966 35.9247 0.388957C35.7117 0.618548 35.6045 0.919489 35.6273 1.22481C35.8425 4.05645 36.8558 6.21724 38.6395 7.64781C40.146 8.85635 41.8597 9.24658 43.0105 9.36416V25.0529C43.0105 25.6847 43.5522 26.1965 44.2205 26.1965C44.8887 26.1965 45.43 25.6847 45.43 25.0529V16.3759H51.4843V26.5772C51.4843 28.7479 50.476 30.7939 48.7181 32.1902L42.2098 37.36L35.702 32.1902C33.9436 30.7939 32.9353 28.7479 32.9353 26.5772V16.3759H39.231C39.8992 16.3759 40.4409 15.8637 40.4409 15.2323C40.4409 14.6005 39.8992 14.0883 39.231 14.0883H31.7258C31.0576 14.0883 30.5159 14.6005 30.5159 15.2323V26.5772C30.5159 29.4252 31.8389 32.1089 34.1453 33.9413L41.4315 39.7289C41.6563 39.9075 41.9327 39.9971 42.2094 39.9971C42.4862 39.9971 42.7633 39.9075 42.9878 39.7289L50.2744 33.9413C52.5804 32.1089 53.9034 29.4252 53.9034 26.5772V15.2323C53.9038 14.6005 53.3621 14.0883 52.6938 14.0883ZM50.0761 2.4493C49.7546 3.94444 49.1028 5.09758 48.1296 5.8872C47.3082 6.55366 46.3708 6.86416 45.6177 7.00726C46.5859 3.8424 48.6852 2.79409 50.0761 2.4493ZM40.2047 5.90434C39.2196 5.11392 38.5602 3.9552 38.237 2.4493C39.6279 2.79369 41.7272 3.8424 42.6954 7.00766C41.9491 6.86616 41.0214 6.55964 40.2047 5.90434Z"
          fill="#7DBBA9"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="54" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
