import React from 'react'

import { SEO } from 'components/fragments/SEO/SEO'
import { Navbar } from 'components/fragments/Navbar/Navbar'
import { Button } from 'components/styles/Button'
import { Floater } from 'components/styles/Floater'
import { Link } from 'components/styles/Link'
import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import { PageSubtitle, PageTitle } from 'components/styles/Typography'

export const ConfirmationView: React.FC = () => {
  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <ContentWrapper>
        <PageTitle>Ditt samtal är bokat!</PageTitle>
        <PageSubtitle>
          Vi ser fram emot att lära känna dig och ditt företag bättre!
        </PageSubtitle>
      </ContentWrapper>
      <Floater>
        <Link to="/">
          <Button variant="secondary" size="lg">
            Tillbaka till portalen
          </Button>
        </Link>
      </Floater>
    </>
  )
}
