import React from 'react'
import styled from '@emotion/styled'

import { SEO } from 'components/fragments/SEO/SEO'
import { Navbar } from 'components/fragments/Navbar/Navbar'
import { Button } from 'components/styles/Button'
import { Card } from 'components/styles/Card'
import { Floater } from 'components/styles/Floater'
import { Link } from 'components/styles/Link'
import {
  PageTitle,
  PageSubtitle,
  PaddedParagraph,
} from 'components/styles/Typography'
import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import FlexGrid from 'components/styles/FlexGrid'
import { Mobile, Desktop } from 'components/styles/Breaks'

const FloaterPadder = styled.div`
  height: calc(122px - 16px);
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export const TEMPLATES = [
  {
    id: 'investeringskalkyl',
    title: 'Investeringskalkyl',
    description:
      'Här kan du modellera kassaflöden, investeringskostnad samt finansiering för din investering.',
  },
  {
    id: 'finansieringskalkyl',
    title: 'Finansieringskalkyl',
    description:
      'Här kan du jämföra två olika finansieringsstrategier och analysera vilken som är mest gynnsam.',
  },
  {
    id: 'tackdikningskalkyl',
    title: 'Täckdikningskalkyl',
    description:
      'Funderar du på hur man egentligen kan ”räkna hem” en investering i täckdikning? Med hjälp av denna kalkyl kan du sätta siffror på täckdikningens värdeskapande.',
  },
  {
    id: 'lagringskalkyl',
    title: 'Lagringskalkyl',
    description:
      'Är det lönsamt att lagra in skörden eller att köpa in insatsvaror tidigt? Vilken gröda är lönsammast att lagra in? Och hur länge? Det är några av alla de insikter som nås med denna kalkyl.',
  },
  {
    id: 'skorderisk-prisrisk',
    title: 'Skörderisk och prisrisk',
    description:
      'Vilken är egentligen den största källan till risk (osäkerhet) i din odling - skörd eller pris? Och hur korrelerar de med varandra? Denna kalkyl ger dig en introduktion till riskhantering i din växtodling.',
  },
]

export const OverviewView: React.FC = () => {
  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <ContentWrapper>
        <PageTitle center>Kalkylmallar</PageTitle>
        <PageSubtitle center>
          Gårdskapitals kalkylmallar hjälper dig att räkna på din investering.
        </PageSubtitle>
        <FlexGrid
          breakpoints={[
            { breakpoint: 0, nColumns: 1 },
            { breakpoint: 600, nColumns: 2 },
            { breakpoint: 1100, nColumns: 3 },
          ]}
        >
          {TEMPLATES.map(template => (
            <Card variant="subtle">
              <h3>{template.title}</h3>
              <PaddedParagraph>{template.description}</PaddedParagraph>
            </Card>
          ))}
        </FlexGrid>
        <Desktop>
          <ButtonWrapper>
            <Link to="/mallar#select">
              <Button variant="primary" size="lg" style={{ width: 300 }}>
                Skicka mig mallarna
              </Button>
            </Link>
          </ButtonWrapper>
        </Desktop>
      </ContentWrapper>
      <Mobile>
        <Floater>
          <Link to="/mallar#select">
            <Button variant="primary" size="lg" fullWidth>
              Skicka mig mallarna
            </Button>
          </Link>
        </Floater>
      </Mobile>
      <FloaterPadder />
    </>
  )
}
