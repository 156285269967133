import React from 'react'
import styled from '@emotion/styled'
import { InlineWidget } from 'react-calendly'

const CenterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const CalendlyView: React.FC = () => {
  return (
    <CenterContentWrapper>
      <InlineWidget
        styles={{ height: '100%', width: '100%', zIndex: 10 }}
        url={
          process.env.GATSBY_CALENDLY_URL +
          '?primary_color=C6E7DD&background_color=002723&text_color=ffffff&hide_gdpr_banner=1'
        }
        prefill={{ email: localStorage.getItem('email') || '' }}
      />
    </CenterContentWrapper>
  )
}
