import React from 'react'
import type { RouteComponentProps } from '@reach/router'

import { SEO } from 'components/fragments/SEO/SEO'
import { Navbar } from 'components/fragments/Navbar/Navbar'
import { Button } from 'components/styles/Button'
import { Card } from 'components/styles/Card'
import { Link } from 'components/styles/Link'
import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import FlexGrid from 'components/styles/FlexGrid'
import {
  PaddedParagraph,
  PageSubtitle,
  PageTitle,
} from 'components/styles/Typography'
import { useStore } from 'components/store'

export const PortalLandingView: React.FC<RouteComponentProps> = () => {
  const {
    state: { userData },
  } = useStore()

  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <ContentWrapper style={{ paddingBottom: 49 }}>
        <PageTitle center>Investeringsportalen</PageTitle>
        <PageSubtitle center>
          Här kan du få hjälp med allt som rör finansieringen av dina
          lantbruksinvesteringar.
        </PageSubtitle>
        <FlexGrid
          breakpoints={[
            { breakpoint: 0, nColumns: 1 },
            { breakpoint: 600, nColumns: 2 },
          ]}
        >
          <Card variant="subtle">
            <h3>Kostnadsfria rådgivningsmöten</h3>
            <PaddedParagraph>Går du i investeringstankar?</PaddedParagraph>
            <PaddedParagraph>
              Vi lyssnar gärna till dina idéer för att se hur vi kan hjälpa just
              dig, även om investeringen är på idéstadiet.
            </PaddedParagraph>
            <Link to="/mote#email">
              <Button variant="primary" size="md" fullWidth>
                Boka ett möte
              </Button>
            </Link>
          </Card>
          <Card variant="subtle">
            <h3>Kalkylmallar</h3>
            <PaddedParagraph>
              Vill du se hur en investering påverkar dina kassaflöden och
              räkenskaper?
            </PaddedParagraph>
            <PaddedParagraph>
              Med våra räknesnurror är det smidigt att räkna på investeringar
              och se hur du kan finansiera dem.
            </PaddedParagraph>
            {/* <Link to="/mallar"> */}
            <Button variant="primary" size="md" fullWidth disabled>
              Kommer snart
            </Button>
            {/* </Link> */}
          </Card>
          <Card variant="pop">
            <h3>Låneansökan</h3>
            <PaddedParagraph>
              Är det dags att säkra finansieringen?
            </PaddedParagraph>
            <PaddedParagraph>
              Fyll i vårt ansökningsformulär så återkommer vi till dig så fort
              som möjligt med ett preliminärt besked och mer information
              (ansökan är ej bindande).
            </PaddedParagraph>
            <Link to={userData?.personalNumber ? '/apply' : '/apply/login'}>
              <Button variant="pop" size="md" fullWidth>
                Ansök om lån
              </Button>
            </Link>
          </Card>
        </FlexGrid>
      </ContentWrapper>
    </>
  )
}
