import React, { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import css from '@emotion/css'

import { SEO } from 'components/fragments/SEO/SEO'
import { Navbar } from 'components/fragments/Navbar/Navbar'
import { Button } from 'components/styles/Button'
import { Card } from 'components/styles/Card'
import { Floater } from 'components/styles/Floater'
import { Link } from 'components/styles/Link'
import { PageTitle, PageSubtitle } from 'components/styles/Typography'
import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import FlexGrid from 'components/styles/FlexGrid'
import { Mobile, Desktop } from 'components/styles/Breaks'
import { TEMPLATES } from 'components/views/TemplatesView/components/OverviewView'

const FloaterPadder = styled.div`
  height: calc(122px - 16px);
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

const CheckboxCard = styled(Card)<{ checked: boolean }>`
  ${({ checked }) => css`
    ${checked ? `outline: 1px solid #ffffff;` : ''}
    padding: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
  `}
`

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
`

export const SelectView: React.FC = () => {
  const [selected, setSelected] = useState<string[]>([])

  const queryString = useMemo(() => {
    const search = new URLSearchParams()
    search.set('files', JSON.stringify(selected))
    return search.toString()
  }, [selected])

  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <ContentWrapper>
        <PageTitle center>Välj mallar</PageTitle>
        <PageSubtitle center>Välj de mallar du vill ha.</PageSubtitle>
        <FlexGrid
          breakpoints={[
            { breakpoint: 0, nColumns: 1 },
            { breakpoint: 600, nColumns: 2 },
            { breakpoint: 1100, nColumns: 3 },
          ]}
        >
          {TEMPLATES.map(template => (
            <CheckboxCard
              key={template.id}
              variant="subtle"
              checked={selected.includes(template.id)}
              onClick={() => {}}
            >
              <label style={{ padding: 24, cursor: 'pointer' }}>
                <Checkbox
                  type="checkbox"
                  onChange={e => {
                    if (selected.includes(template.id)) {
                      setSelected(prev => prev.filter(t => t !== template.id))
                      return
                    }
                    if (e.target.checked) {
                      setSelected(prev => [...prev, template.id])
                    }
                  }}
                />
                <span>{template.title}</span>
              </label>
            </CheckboxCard>
          ))}
        </FlexGrid>
        <Desktop>
          <ButtonWrapper>
            <Link to={`/mallar?${queryString}#email`}>
              <Button
                variant="secondary"
                size="lg"
                style={{ width: 300 }}
                disabled={selected.length < 1}
              >
                Nästa
              </Button>
            </Link>
          </ButtonWrapper>
        </Desktop>
      </ContentWrapper>
      <Mobile>
        <Floater>
          <Link to={`/mallar?${queryString}#email`}>
            <Button
              variant="secondary"
              size="lg"
              disabled={selected.length < 1}
              fullWidth
            >
              Nästa
            </Button>
          </Link>
        </Floater>
      </Mobile>
      <FloaterPadder />
    </>
  )
}
