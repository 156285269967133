import React, { useState } from 'react'
import { z } from 'zod'
import axios from 'axios'
import { navigate } from 'gatsby'
import personnummer from 'personnummer.js'

import { auth } from 'utils/firebase'
import firebase from 'firebase/app'
import { Role } from 'types/custom'
import { useStore } from 'components/store'
import { QuestionView } from '../QuestionView/QuestionView'

interface LoginViewProps {
  role: Role
}

const getTitle = (
  role: Role,
  isLoggingIn: boolean,
  isServerError: boolean
): string => {
  if (isServerError) {
    return 'Något gick fel, försök igen'
  } else if (isLoggingIn) {
    return 'Starta BankID-appen'
  } else if (role === Role.LOAN_APPLICANT) {
    return 'Logga in med BankID för att komma till Gårdskapitals ansökningssida'
  } else if (role === Role.INVESTOR) {
    return 'Logga in med BankID för att investera i lån via Gårdskapital'
  }
  return 'Något gick fel, försök igen'
}

export const LoginView: React.FC<LoginViewProps> = ({ role }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [isServerError, setIsServerError] = useState(false)

  const { dispatch } = useStore()

  const handleLogin = async (values: { [x: string]: string }) => {
    setIsLoggingIn(true)
    setIsServerError(false)

    let res
    try {
      res = await axios.post(`${process.env.GATSBY_API_URL}/login`, {
        personalNumber: personnummer.normalise(values.personalnumber),
      })
    } catch (err) {
      setIsServerError(true)
      setIsLoggingIn(false)
      return
    }

    const token = res.data

    try {
      // Persistence is set very strictly to comply with BankID rules.
      // The optimal solution would be to invalidate the token and require auth again after 1 hour of inactivity.
      await auth?.setPersistence(firebase.auth.Auth.Persistence.NONE)
      await auth?.signInWithCustomToken(token)
      if (role === Role.INVESTOR) {
        dispatch({ type: 'setUserRole', data: Role.INVESTOR })
        navigate('/invest')
      } else {
        dispatch({ type: 'setUserRole', data: Role.LOAN_APPLICANT })
        navigate('/apply')
      }
      setIsLoggingIn(false)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <QuestionView
      title={getTitle(role, isLoggingIn, isServerError)}
      validator={z.string().refine(val => personnummer.validate(val))}
      errorMessage="Ogiltigt personnummer"
      type="text"
      name="personalnumber"
      placeholder="Personnummer"
      initialValue=""
      buttonText="Logga in"
      isLoading={isLoggingIn}
      onSubmit={handleLogin}
    />
  )
}
