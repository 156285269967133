import React from 'react'
import styled from '@emotion/styled'
import { z } from 'zod'
import { Formik } from 'formik'

import { SEO } from 'components/fragments/SEO/SEO'
import { Navbar } from 'components/fragments/Navbar/Navbar'
import { Button } from 'components/styles/Button'
import { Floater } from 'components/styles/Floater'
import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import { PageTitle } from 'components/styles/Typography'
import { Input } from 'components/styles/Form'
import { ClipLoader } from 'react-spinners'
import { Desktop, Mobile } from 'components/styles/Breaks'

const ErrorText = styled.span`
  color: red;
`

interface QuestionViewProps {
  title: string
  validator: z.ZodTypeAny
  errorMessage: string
  type: string
  name: string
  placeholder?: string
  initialValue: string
  buttonText: string
  isLoading?: boolean
  onSubmit: (values: { [x: string]: string }) => any
}

const QuestionViewMobile: React.FC<QuestionViewProps> = ({
  title,
  name,
  initialValue,
  validator,
  errorMessage,
  onSubmit,
  type,
  placeholder,
  buttonText,
}) => (
  <>
    <SEO title="Gårdskapital" />
    <Navbar />
    <NavOffset />
    <ContentWrapper>
      <PageTitle center>{title}</PageTitle>
      <Formik
        initialValues={{ [name]: initialValue }}
        validate={values => {
          const errors: { [key in keyof typeof values]?: string } = {}
          const data = validator.safeParse(values[name])
          if (!data.success) {
            errors[name] = errorMessage
          }
          return errors
        }}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,

          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <Input
              type={type}
              name={name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[name]}
              placeholder={placeholder}
              isInvalid={!!errors[name] && !!touched[name]}
            />
            {errors[name] && touched[name] && (
              <ErrorText>{errors[name]}</ErrorText>
            )}
            <Floater style={{ marginLeft: -16 }}>
              <Button
                type="submit"
                variant="secondary"
                size="lg"
                disabled={!!errors[name] || !values[name]}
                fullWidth
              >
                {buttonText}
              </Button>
            </Floater>
          </form>
        )}
      </Formik>
    </ContentWrapper>
  </>
)

const CenterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: -110px 0;
  align-items: center;
`

const QuestionWrapper = styled.div`
  max-width: 390px;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const QuestionViewDesktop: React.FC<QuestionViewProps> = ({
  title,
  validator,
  errorMessage,
  type,
  name,
  placeholder,
  initialValue,
  buttonText,
  isLoading,
  onSubmit,
}) => {
  return (
    <>
      <SEO title="Gårdskapital" />
      <Navbar />
      <NavOffset />
      <CenterContentWrapper>
        <QuestionWrapper>
          <PageTitle center>{title}</PageTitle>
          {isLoading ? (
            <LoaderWrapper>
              <ClipLoader color="#ffffff" />
            </LoaderWrapper>
          ) : (
            <Formik
              initialValues={{ [name]: initialValue }}
              validate={values => {
                const errors: { [key in keyof typeof values]?: string } = {}
                const data = validator.safeParse(values[name])
                if (!data.success) {
                  errors[name] = errorMessage
                }
                return errors
              }}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,

                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    type={type}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    placeholder={placeholder}
                    isInvalid={!!errors[name] && !!touched[name]}
                  />
                  {errors[name] && touched[name] && (
                    <ErrorText>{errors[name]}</ErrorText>
                  )}
                  <Button
                    type="submit"
                    variant="secondary"
                    size="md"
                    style={{ marginTop: 24 }}
                    disabled={!!errors[name] || !values[name]}
                    fullWidth
                  >
                    {buttonText}
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </QuestionWrapper>
      </CenterContentWrapper>
    </>
  )
}

export const QuestionView: React.FC<QuestionViewProps> = props => {
  return (
    <>
      <Mobile>
        <QuestionViewMobile {...props} />
      </Mobile>
      <Desktop>
        <QuestionViewDesktop {...props} />
      </Desktop>
    </>
  )
}
