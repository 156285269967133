import styled from '@emotion/styled'
import css from '@emotion/css'

export const Input = styled.input<{ isInvalid: boolean }>`
  ${({ isInvalid }) => css`
    width: 100%;
    height: 56px;
    border-radius: 8px;
    background: #0d322e;
    border: 1px solid ${isInvalid ? 'red' : '#ffffff'};
    box-sizing: border-box;
    padding-left: 14px;
    color: #ffffff;
    &::placeholder {
      color: #ffffff;
      opacity: 0.8;
    }
    margin-bottom: 4px;
  `}
`
