import styled from '@emotion/styled'
import css from '@emotion/css'

export const NavOffset = styled.div<{ fullNav?: boolean }>`
  ${({ fullNav }) => css`
    height: 90px;
    ${fullNav ? 'margin-bottom: 32px;' : ''}
    @media (min-width: 420px) {
      height: 110px;
    }
  `}
`

export const ContentWrapper = styled.div<{ narrow?: boolean }>`
  padding: 0 16px;
  max-width: ${({ narrow }) => (narrow ? 600 : 1100)}px;
  margin: 0 auto;
`
