import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'

import { ContentWrapper, NavOffset } from 'components/styles/Layout'
import { LoggedInNavbar } from 'components/fragments/LoggedInNavbar/LoggedInNavbar'
import { useStore } from 'components/store'
import { SEO } from 'components/fragments/SEO/SEO'
import { PageTitle } from 'components/styles/Typography'
import { Button, ButtonGroup } from 'components/styles/Button'

const TextWrapper = styled.div`
  padding-bottom: 32px;
`

export const ApplyStartView: React.FC = () => {
  const { dispatch } = useStore()

  return (
    <>
      <SEO title="Gårdskapital" />
      <LoggedInNavbar />
      <NavOffset fullNav />
      <ContentWrapper narrow>
        <TextWrapper>
          <PageTitle>Välkommen till Gårdskapitals ansökningssida!</PageTitle>
          <p>
            <b>Låneansökan</b>
            <br />
            Här ansöker du om ett nytt lån. Processen beräknas ta upp till 5
            minuter att fullfölja. Om du sedan tidigare gjort en intresseanmälan
            hos oss får du ett snabbspår.
            <br />
            <br />
            <b>Ändra befintligt lån</b>
            <br />
            Här har du möjlighet att göra följande:
            <ul>
              <li>Ändra amorteringstakt</li>
              <li>Förtidslösa lån</li>
              <li>Refinansiera/förlänga lån</li>
              <li>Utöka befintligt lån</li>
            </ul>
          </p>
        </TextWrapper>
        <ButtonGroup>
          <Button
            variant="primary"
            size="md"
            style={{ maxWidth: 350 }}
            onClick={() => {
              dispatch({
                type: 'setApplicationType',
                data: 'application',
              })
              navigate('/apply/acceptera-villkor')
            }}
          >
            Låneansökan
          </Button>
          <Button
            variant="pop"
            size="md"
            style={{ maxWidth: 350 }}
            onClick={() => {
              dispatch({
                type: 'setApplicationType',
                data: 'noticeOfInterest',
              })
              navigate('/apply/acceptera-villkor')
            }}
          >
            Ändra befintligt lån
          </Button>
        </ButtonGroup>
      </ContentWrapper>
    </>
  )
}
