import React, { useEffect } from 'react'
import { Router as ReachRouter } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

import { PortalLandingView } from 'components/views/PortalLandingView/PortalLandingView'
import { TemplatesView } from 'components/views/TemplatesView/TemplatesView'
import { LoginView } from 'components/views/LoginView/LoginView'
import { ApplyStartView } from 'components/views/ApplyStartView/ApplyStartView'
import { InvestStartView } from 'components/views/InvestStartView/InvestStartView'
import { AcceptTermsView } from 'components/views/AcceptTermsView/AcceptTermsView'
import { TermsView } from 'components/views/TermsView/TermsView'
import { FormView } from 'components/views/FormView/FormView'
import { MeetingView } from 'components/views/MeetingView/MeetingView'
import { CTerms } from 'types/contentful'
import { activateActivityTracker } from 'utils/inactivity'
import { Role } from 'types/custom'

import { ProtectedRoute } from './ProtectedRoute'

interface LoginRouteProps {
  path: string
  role: Role
}

const Login: React.FC<LoginRouteProps> = ({ role }) => <LoginView role={role} />

export const Router: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTerms {
        nodes {
          content {
            raw
          }
          slug
        }
      }
    }
  `)

  useEffect(() => {
    activateActivityTracker()
  }, [])

  return (
    <ReachRouter>
      <PortalLandingView path="/" />
      <MeetingView path="/mote" />
      <TemplatesView path="/mallar" />
      <ProtectedRoute path="/apply" component={ApplyStartView} />
      <ProtectedRoute path="/invest" component={InvestStartView} />
      <Login path="/apply/login" role={Role.LOAN_APPLICANT} />
      <Login path="/invest/login" role={Role.INVESTOR} />
      <ProtectedRoute
        path="/apply/acceptera-villkor"
        component={AcceptTermsView}
      />
      {data.allContentfulTerms.nodes.map((node: CTerms) => {
        return (
          <ProtectedRoute
            key={node.slug}
            path={node.slug}
            component={TermsView}
            rawContent={node.content.raw}
          />
        )
      })}
      <ProtectedRoute path="/apply/formular" component={FormView} />
    </ReachRouter>
  )
}
