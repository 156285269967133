import styled from '@emotion/styled'

export const Mobile = styled.div`
  @media (min-width: 420px) {
    display: none;
  }
`

export const Desktop = styled.div`
  @media (max-width: 419px) {
    display: none;
  }
`
