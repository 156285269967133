import React, { useCallback } from 'react'
import { useLocation } from '@reach/router'
import { z } from 'zod'
import { navigate } from 'gatsby'
import type { RouteComponentProps } from '@reach/router'

import { QuestionView } from 'components/views/QuestionView/QuestionView'
import { NewsletterSignupView } from 'components/views/NewsletterSignupView/NewsletterSignupView'
import { CalendlyView } from './components/CalendlyView'
import { ConfirmationView } from './components/ConfirmationView'

export const MeetingView: React.FC<RouteComponentProps> = () => {
  const location = useLocation()

  const saveEmail = useCallback(values => {
    localStorage.setItem('email', values.email)

    if (!localStorage.getItem('hasSeenNewsletterSignup')) {
      navigate('/mote#newsletter')
    } else {
      navigate('/mote#boka')
    }
  }, [])

  switch (location.hash) {
    case '#email':
      return (
        <QuestionView
          title="Ange din e-post för att se lediga tider"
          validator={z.string().email()}
          errorMessage="Ogiltig e-post"
          type="email"
          name="email"
          placeholder="namn@adress.se"
          initialValue={localStorage.getItem('email') || ''}
          buttonText="Nästa"
          onSubmit={saveEmail}
        />
      )
    case '#newsletter':
      return <NewsletterSignupView nextPath="/mote#boka" />
    case '#boka':
      return <CalendlyView />
    case '#bekraftelse':
      return <ConfirmationView />
    default:
      return null
  }
}
