import styled from '@emotion/styled'
import css from '@emotion/css'
import FlexGrid from './FlexGrid'

type CardVariant = 'subtle' | 'pop'

const getCardColor = (variant: CardVariant) => {
  if (variant === 'subtle') {
    return { background: '#103a34', color: '#ffffff' }
  } else if (variant === 'pop') {
    return { background: '#EFE8DF', color: '#000000' }
  } else {
    return { background: 'white' }
  }
}

export const Card = styled(FlexGrid.Card)<{ variant: CardVariant }>`
  ${({ variant }) => {
    const { background, color } = getCardColor(variant)
    return css`
      flex-direction: column;
      padding: 16px;
      background: ${background};
      color: ${color};
      border-radius: 16px;
    `
  }}
`
