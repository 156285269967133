import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'

import { ContentWrapper } from 'components/styles/Layout'
import { LoggedInNavbar } from 'components/fragments/LoggedInNavbar/LoggedInNavbar'
import { NewWindowIcon } from 'assets/svg/NewWindow'
import { useStore } from 'components/store'
import { SEO } from 'components/fragments/SEO/SEO'
import { NavOffset } from 'components/styles/Layout'
import { PageSubtitle } from 'components/styles/Typography'
import { Button } from 'components/styles/Button'

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: ${props => props.theme.space.safe.mobile}px;
  width: calc(100% - ${props => 2 * props.theme.space.main.mobile}px);
  @media screen and (min-width: 600px) {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomContentWrapper = styled(ContentWrapper)`
  @media (min-width: 420px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 110px);
    margin-top: -110px;
  }
`

export const FormView: React.FC = () => {
  const {
    state: { applicationType },
  } = useStore()

  let formUrl
  if (applicationType === 'application') {
    formUrl = process.env.GATSBY_APPLICATION_FORM_URL
  } else if (applicationType === 'noticeOfInterest') {
    formUrl = process.env.GATSBY_INTEREST_FORM_URL
  } else {
    navigate('/')
    return null
  }

  return (
    <>
      <SEO title="Gårdskapital" />
      <LoggedInNavbar />
      <NavOffset fullNav />
      <CustomContentWrapper>
        <PageSubtitle center>
          Formuläret öppnas i nytt fönster. Tveka inte att höra av dig vid
          frågor.
        </PageSubtitle>
        <ButtonWrapper>
          <a href={formUrl} target="_blank">
            <Button
              variant="primary"
              size="lg"
              fullWidth
              style={{ maxWidth: 380 }}
            >
              <ButtonContentWrapper>
                <span style={{ marginRight: 8 }}>
                  {applicationType === 'noticeOfInterest'
                    ? 'Till ändringsanmälan'
                    : 'Till ansökningsformuläret'}
                </span>
                <NewWindowIcon />
              </ButtonContentWrapper>
            </Button>
          </a>
        </ButtonWrapper>
      </CustomContentWrapper>
    </>
  )
}
