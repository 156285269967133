import styled from '@emotion/styled'
import css from '@emotion/css'

type ButtonVariant = 'primary' | 'secondary' | 'pop' | 'text'
type ButtonSize = 'md' | 'lg'

const getButtonColor = (variant: ButtonVariant) => {
  if (variant === 'primary') {
    return { background: '#8acfbb', color: '#000a09' }
  } else if (variant === 'secondary') {
    return { background: '#ffffff', color: '#000000' }
  } else if (variant === 'pop') {
    return { background: '#F5AC55', color: '#000000' }
  } else if (variant === 'text') {
    return { background: 'none', color: '#ffffff' }
  } else {
    if (!variant) {
      throw new Error('No color passed to button')
    }
    throw new Error('Invalid color passed to button')
  }
}

const getButtonSize = (size: ButtonSize) => {
  if (size === 'lg') {
    return '61px'
  } else if (size === 'md') {
    return '54px'
  } else {
    if (!size) {
      throw new Error('No size passed to button')
    }
    throw new Error('Invalid size passed to button')
  }
}

export const Button = styled.button<{
  variant: ButtonVariant
  size: ButtonSize
  center?: boolean
  fullWidth?: boolean
}>`
  ${({ variant, size, center, fullWidth }) => {
    const { background, color } = getButtonColor(variant)
    const buttonSize = getButtonSize(size)
    return css`
      width: ${fullWidth ? '100%;' : ''}
      align-self: center;
      height: ${buttonSize};
      background: ${background};
      color: ${color};
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: ${variant === 'text' ? 400 : 700};
      user-select: none;
      padding: 0 32px;
      ${center ? 'margin: 0 auto;' : ''}
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    `
  }}
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  a,
  button {
    margin-bottom: 14px;
    @media (min-width: 420px) {
      margin-bottom: 18px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
